<template>
    <div>
        <context-title bare :passed-actions="actions" title="Edit receipt template"/>
        <v-container fluid>
            <template v-if="isTemplateLoading">
                <v-skeleton-loader
                    type="chip, article, article"
                />
            </template>
            <template v-else>
                <v-row>
                    <v-col>
                        <v-chip class="ma-1" label small outlined>Airport: {{receiptData.airportIdent}}</v-chip>
                        <v-chip class="ma-1" label small outlined v-if="receiptData.waypointID">Waypoint: {{receiptData.waypointID}}</v-chip>
                        <v-chip class="ma-1" label small outlined>Language: {{receiptData.languageCode}}</v-chip>
                        <v-chip class="ma-1" label small outlined>Order status: {{orderStatusLabelById(receiptData.orderStatus)}}</v-chip>
                        <v-chip class="ma-1" label small outlined>Order type: {{orderTypeLabelById(receiptData.orderType)}}</v-chip>
                        <v-chip class="ma-1" label small outlined>Platform type: {{platformLabelById(receiptData.platformType)}}</v-chip>
                        <v-chip class="ma-1" label small outlined v-if="receiptData.partnerCode">Partner code: {{receiptData.partnerCode}}</v-chip>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-checkbox label="Is Enabled" v-model="receiptData.isEnabled" />
                        <v-textarea label="Header logo HTML" v-model="receiptData.headerLogoHtml" rows="1"/>
                        <v-textarea label="Thank you message HTML" v-model="receiptData.thankYouMessageHtml" rows="1"/>
                        <v-textarea label="Template description" v-model="receiptData.templateDescription" rows="1"/>
                        <v-textarea label="Email subject template" v-model="receiptData.emailSubjectTemplate" rows="1"/>
                        <v-checkbox label="Show Green Box" v-model="receiptData.showGreenBox" />
                        <v-textarea label="Tags" v-model="receiptData.tags" rows="1"/>
                        <v-text-field label="Template file" v-model="receiptData.templateFile" />
                    </v-col>
                </v-row>
            </template>
        </v-container>
    </div>
</template>

<script>
import waypointIdFromQuery from "mixins/waypointIdFromQuery";
import ContextTitle from "components/context_title";
import {mapActions, mapGetters} from "vuex";
import {OperationStatus} from "helpers/common";
import metadata from "mixins/receiptsMetadata";

export default {
    name: "edit.vue",
    mixins: [waypointIdFromQuery, metadata],
    components: {
        ContextTitle,
    },
    data: () =>({
        receiptData: {
            headerLogoHtml: null,
            thankYouMessageHtml: null,
            emailSubjectTemplate: null,
            templateDescription: null,
            UsingDefault: false,
            showGreenBox: false,
            isEnabled: true,
            templateFile: null,
            tags: null,

            airportIdent: null,
            waypointID: 0,
            languageCode: null,
            orderStatus: 0,
            orderType: 0,
            partnerCode: null,
            platformType: 0,
        },
    }),
    mounted() {
        if (!this.waypointId) {
            this.fetchReceiptTemplates();
        } else if (this.receiptsStatus === OperationStatus.Unknown || this.receiptsStatus === OperationStatus.Failed) {
            this.fetchReceiptTemplates(this.waypointId);
        } else if (this.receiptsStatus === OperationStatus.Succeeded) {
            this.receiptData = {
                ...this.receipt,
            }
        }
    },
    computed: {
        ...mapGetters("ReceiptsStore", ["receiptTemplates", "receiptsStatus"]),
        actions() {
            const cancel = {
                type: "cancel",
                display: "Cancel",
                run: this.cancel,
            };

            const save = {
                type: "proceed",
                display: "Save",
                run: this.save,
            };

            return [cancel, save];
        },
        id() {
            return this.$route.params.templateID;
        },
        waypointId() {
            return this.$route.params.waypointID;
        },
        receipt() {
            return this.receiptTemplates.find(x => x.templateID === this.id);
        },
        isTemplateLoading() {
            return this.receiptsStatus === OperationStatus.Unknown || this.receiptsStatus === OperationStatus.InProgress;
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.previousRoute = from
        })
    },
    methods: {
        ...mapActions("ReceiptsStore", ["fetchReceiptTemplates"]),
        navigateBackToReceipts() {
            this.$router.push(this.previousRoute && this.previousRoute.name === 'receipts'
                ? this.previousRoute
                : {name: 'receipts'}
            );
        },
        save() {

        },
        cancel() {
            this.navigateBackToReceipts();
        },
    },
    watch: {
        receipt(val) {
            if (val) {
                this.receiptData = {
                    ...val,
                };
            }
        }
    }
}
</script>

<style scoped>

</style>
