var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("context-title", {
        attrs: {
          bare: "",
          "passed-actions": _vm.actions,
          title: "Edit receipt template",
        },
      }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _vm.isTemplateLoading
            ? [
                _c("v-skeleton-loader", {
                  attrs: { type: "chip, article, article" },
                }),
              ]
            : [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { label: "", small: "", outlined: "" },
                          },
                          [
                            _vm._v(
                              "Airport: " + _vm._s(_vm.receiptData.airportIdent)
                            ),
                          ]
                        ),
                        _vm.receiptData.waypointID
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "ma-1",
                                attrs: { label: "", small: "", outlined: "" },
                              },
                              [
                                _vm._v(
                                  "Waypoint: " +
                                    _vm._s(_vm.receiptData.waypointID)
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { label: "", small: "", outlined: "" },
                          },
                          [
                            _vm._v(
                              "Language: " +
                                _vm._s(_vm.receiptData.languageCode)
                            ),
                          ]
                        ),
                        _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { label: "", small: "", outlined: "" },
                          },
                          [
                            _vm._v(
                              "Order status: " +
                                _vm._s(
                                  _vm.orderStatusLabelById(
                                    _vm.receiptData.orderStatus
                                  )
                                )
                            ),
                          ]
                        ),
                        _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { label: "", small: "", outlined: "" },
                          },
                          [
                            _vm._v(
                              "Order type: " +
                                _vm._s(
                                  _vm.orderTypeLabelById(
                                    _vm.receiptData.orderType
                                  )
                                )
                            ),
                          ]
                        ),
                        _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { label: "", small: "", outlined: "" },
                          },
                          [
                            _vm._v(
                              "Platform type: " +
                                _vm._s(
                                  _vm.platformLabelById(
                                    _vm.receiptData.platformType
                                  )
                                )
                            ),
                          ]
                        ),
                        _vm.receiptData.partnerCode
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "ma-1",
                                attrs: { label: "", small: "", outlined: "" },
                              },
                              [
                                _vm._v(
                                  "Partner code: " +
                                    _vm._s(_vm.receiptData.partnerCode)
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-checkbox", {
                          attrs: { label: "Is Enabled" },
                          model: {
                            value: _vm.receiptData.isEnabled,
                            callback: function ($$v) {
                              _vm.$set(_vm.receiptData, "isEnabled", $$v)
                            },
                            expression: "receiptData.isEnabled",
                          },
                        }),
                        _c("v-textarea", {
                          attrs: { label: "Header logo HTML", rows: "1" },
                          model: {
                            value: _vm.receiptData.headerLogoHtml,
                            callback: function ($$v) {
                              _vm.$set(_vm.receiptData, "headerLogoHtml", $$v)
                            },
                            expression: "receiptData.headerLogoHtml",
                          },
                        }),
                        _c("v-textarea", {
                          attrs: { label: "Thank you message HTML", rows: "1" },
                          model: {
                            value: _vm.receiptData.thankYouMessageHtml,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.receiptData,
                                "thankYouMessageHtml",
                                $$v
                              )
                            },
                            expression: "receiptData.thankYouMessageHtml",
                          },
                        }),
                        _c("v-textarea", {
                          attrs: { label: "Template description", rows: "1" },
                          model: {
                            value: _vm.receiptData.templateDescription,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.receiptData,
                                "templateDescription",
                                $$v
                              )
                            },
                            expression: "receiptData.templateDescription",
                          },
                        }),
                        _c("v-textarea", {
                          attrs: { label: "Email subject template", rows: "1" },
                          model: {
                            value: _vm.receiptData.emailSubjectTemplate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.receiptData,
                                "emailSubjectTemplate",
                                $$v
                              )
                            },
                            expression: "receiptData.emailSubjectTemplate",
                          },
                        }),
                        _c("v-checkbox", {
                          attrs: { label: "Show Green Box" },
                          model: {
                            value: _vm.receiptData.showGreenBox,
                            callback: function ($$v) {
                              _vm.$set(_vm.receiptData, "showGreenBox", $$v)
                            },
                            expression: "receiptData.showGreenBox",
                          },
                        }),
                        _c("v-textarea", {
                          attrs: { label: "Tags", rows: "1" },
                          model: {
                            value: _vm.receiptData.tags,
                            callback: function ($$v) {
                              _vm.$set(_vm.receiptData, "tags", $$v)
                            },
                            expression: "receiptData.tags",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: { label: "Template file" },
                          model: {
                            value: _vm.receiptData.templateFile,
                            callback: function ($$v) {
                              _vm.$set(_vm.receiptData, "templateFile", $$v)
                            },
                            expression: "receiptData.templateFile",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }